// @ is an alias to /src
import { Searchbar, Hero, Cta, CtaGrid } from "@/components";
import { mapActions } from "vuex";

export default {
	name: "Home",
	components: {
		Searchbar,
		Hero,
		Cta,
		CtaGrid,
	},
	data: () => ({
		gridVendingMachines: {
			title: "Een automaat voor elk product",
			imagesGrid1: [
				require("@/assets/images/ctagrid1.png"),
				require("@/assets/images/ctagrid2.png"),
				require("@/assets/images/ctagrid3.png"),
				require("@/assets/images/ctagrid4.png"),
				require("@/assets/images/ctagrid5.png"),
				require("@/assets/images/ctagrid6.png"),
			],
			description:
				"Op zoek naar een broodautomaat? Aardbeien? Bloemen? \
        Of toch liever een verse maaltijd? Vind alle automaten in uw buurt.",
			buttonText: "Ontdek automaten",
			rowReverse: false,
		},
		gridCollaborations: {
			title: "Collaborations!",
			imagesGrid2: [
				require("@/assets/images/ctagrid7.png"),
				require("@/assets/images/ctagrid8.png"),
				require("@/assets/images/ctagrid9.png"),
				require("@/assets/images/ctagrid10.png"),
				require("@/assets/images/ctagrid11.png"),
			],
			description:
				"Op zoek naar een broodautomaat? Aardbeien? Bloemen? \
        Of toch liever een verse maaltijd? Vind alle automaten in uw buurt.",
			buttonText: "Ontdek automaten",
			rowReverse: false,
		},
		cta: {
			title: "Bekijk de automaten in jouw buurt.",
			imgPath: require("@/assets/images/imgCta22.png"),
			description:
				"De mapweergave maakt het mogelijk om in één oogopslag \
      de automaten in jouw buurt te bekijken.",
			buttonText: "Ontdek automaten",
			rowReverse: true,
		},
	}),
	methods: {
		...mapActions("machine", {
			fetchCategories: "FETCH_CATEGORIES",
		}),
	},
	mounted() {
		this.fetchCategories();
	}
};
